// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-blog-details-js": () => import("./../../../src/components/blog/blog-details.js" /* webpackChunkName: "component---src-components-blog-blog-details-js" */),
  "component---src-components-blog-blog-js": () => import("./../../../src/components/blog/blog.js" /* webpackChunkName: "component---src-components-blog-blog-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-conseil-js": () => import("./../../../src/pages/conseil.js" /* webpackChunkName: "component---src-pages-conseil-js" */),
  "component---src-pages-contactpage-js": () => import("./../../../src/pages/contactpage.js" /* webpackChunkName: "component---src-pages-contactpage-js" */),
  "component---src-pages-formation-js": () => import("./../../../src/pages/formation.js" /* webpackChunkName: "component---src-pages-formation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-luzean-js": () => import("./../../../src/pages/luzean.js" /* webpackChunkName: "component---src-pages-luzean-js" */),
  "component---src-pages-qawhat-js": () => import("./../../../src/pages/qawhat.js" /* webpackChunkName: "component---src-pages-qawhat-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */)
}

